import React from 'react';
import {
  Page,
} from 'framework7-react';
import FillTheForm from './fill-the-form';

export default () => {
  return (
    <Page className="page-home">
      {/* <Navbar large transparent sliding={false} mdCenterTitle>
        <NavLeft>
          <Link panelOpen="left" iconIos="f7:menu" iconMd="material:menu" />
        </NavLeft>
        <NavTitle sliding>Framework7 React</NavTitle>
        <NavRight>
          <Link
            searchbarEnable=".searchbar-components"
            iconIos="f7:search"
            iconMd="material:search"
          />
        </NavRight>
         <NavTitleLarge>Framework7 React Ny</NavTitleLarge> 
        <Searchbar
          className="searchbar-components"
          searchContainer=".components-list"
          searchIn="a"
          expandable
        />
      </Navbar> */}
      <FillTheForm></FillTheForm>
      
    </Page>
  );
};
