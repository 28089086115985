import React from 'react';
import Framework7 from 'framework7/lite/bundle';
import Framework7React from 'framework7-react';
import App from './app.jsx'; // Import thành phần chính

import 'framework7/css/bundle'; // Import CSS Framework7
import './styles/app.css'; // Import CSS tùy chỉnh
import './styles/main.scss'; // Import CSS tùy chỉnh
import './styles/iconfont.css'; // Import CSS iconfont

import "@fontsource/oswald"; // Defaults to weight 400
import "@fontsource/oswald/400.css"; // Specify weight

// Kích hoạt Framework7 React Plugin
Framework7.use(Framework7React);

const MainApp = () => {
  // Xử lý các cấu hình nếu cần (safe-areas, dark mode)
  if (document.location.href.includes('safe-areas')) {
    const html = document.documentElement;
    if (html) {
      html.style.setProperty('--f7-safe-area-top', '44px');
      html.style.setProperty('--f7-safe-area-bottom', '34px');
    }
  }
  if (document.location.search.indexOf('mode=') >= 0) {
    const mode = document.location.search.split('mode=')[1].split('&')[0];
    if (mode === 'dark') document.documentElement.classList.add('dark');
  }
  if (document.location.href.includes('example-preview')) {
    document.documentElement.classList.add('example-preview');
  }

  return <App />;
};

export default MainApp;
