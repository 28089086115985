import React from 'react';
import {
    Page,
    Block,
    Button,
    AccordionContent,
    Icon,
} from 'framework7-react';

import myLogo from '../img/Wordbank.svg';
import iconCaret from '../img/caret-down.svg';
import imgAc from '../img/abc_as.png';


const RegisterLandingPage = () => {
    return (
        <Page className="register-landing-page">
            {/* Header Section */}
            <section className="header-section">
                <Block className="logo">
                    <img src={myLogo} alt="Logo" />
                </Block>
                <Block className="title">
                    <p>Cải thiện tiếng anh giao tiếp sau 21 ngày!</p>
                </Block>
                <Block className="subtitle">
                    <p>Đồng hành cùng bạn chinh phục giao tiếp<br></br>
                        tiếng Anh thông qua học cụm từ và hình ảnh ngữ cảnh.</p>
                </Block>

                {/* Registration Form Section */}
                <Block className="register-form-section">
                    <Block className="text-reg">
                        <p>Already have an account?<a href='#'>Sign in</a></p>
                    </Block>
                    <div className="form-container">
                        <input
                            type="email"
                            className="email-input"
                            placeholder="Email address"
                        />
                        <Button fill className="sign-up-button">
                            Sign up
                        </Button>
                    </div>
                </Block>
                <Block className="end-text">
                    <p>Hệ thống sẽ giử mật khẩu về Email của bạn.<br></br>
                        Nếu không thấy vui lòng kiểm tra trong mục “Spam”.</p>
                </Block>
                <a href='#' class="btnLearnmore">
                    <div class="learnmore">Tìm hiểu thêm</div>
                    <div class="icon">
                        <img src={iconCaret} alt="Down" />
                    </div>
                </a>

            </section>
            {/* Why You Can't Speak English Section */}
            <section className='whycanspeak'>
                <img src={imgAc} alt="ADV" style={{ width: '100%' }} />
                <div class="cannottalk">Bạn Đã Học Tiếng Anh Nhưng Vẫn Không Thể Nói Được?</div>
                <div class="anyway">Dù bạn đã học từ vựng và ngữ pháp, nhưng khi giao tiếp, bạn vẫn cảm thấy ngập ngừng và thiếu tự tin. <br></br>Vì sao?</div>
                <Block className="why-not">

                    <div class="group-box">
                        <div class="group">
                            <div class="hoc-ngu-phap">
                                <ul class="hoc-list">
                                    <li>
                                        <div class="hoc-text">Học từ vựng, ngữ pháp rời rạc:</div>
                                    </li>
                                </ul>
                            </div>
                            <div class="info">Bạn biết từ, hiểu cấu trúc, nhưng khi cần ghép chúng thành câu hoàn chỉnh, bạn mất quá nhiều thời gian suy nghĩ.</div>
                            <div class="cum-tu">
                                <ul class="cum-tu-list">
                                    <li>
                                        <div class="cum-tu-text">Không quen với cụm từ thông dụng:</div>
                                    </li>
                                </ul>
                            </div>
                            <div class="ban-ngu-giao-tiep">Người bản ngữ giao tiếp bằng cụm từ tự nhiên, nhưng bạn lại cố gắng dịch từng từ một, khiến câu nói thiếu tự nhiên và không trôi chảy.</div>
                            <div class="luyen-tap">
                                <ul class="luyen-tap-list">
                                    <li>
                                        <div class="luyen-tap-text">Thiếu môi trường luyện tập thực tế:</div>
                                    </li>
                                </ul>
                            </div>
                            <div class="ngai-noi">
                                <div class="ngai-noi-text">Ngại nói, sợ sai.</div>
                                <div class="ngai-noi-label">
                                    <p class="ngai-label">
                                        <span class="no-sua-label">Không ai sửa lỗi:</span>
                                        <span class="khong-sai">Bạn không biết mình nói đúng hay sai, dẫn đến việc lặp lại lỗi.</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </Block></section>

            {/* Solution Section */}
            <Block className="solution">
                <h3>Giải Pháp Wordbank Đồng Hành Cùng Bạn</h3>
                <p>
                    Chúng tôi không chỉ bán sản phẩm, mà còn đồng hành cùng bạn. Với phương pháp học cụm từ theo ngữ cảnh và công nghệ AI cá nhân hóa, bạn sẽ chinh phục giao tiếp tiếng Anh dễ dàng.
                </p>
            </Block>

            {/* Main Features Section */}
            <Block className="main-features">
                <h3>Tính Năng Chính Của Wordbank</h3>
                <div className="feature-list">
                    <div className="feature-item">
                        <Icon className="ai-icon" />
                        <p>AI Cá Nhân Hóa Bài Tập</p>
                    </div>
                    <div className="feature-item">
                        <Icon className="image-icon" />
                        <p>Học Cụm Từ Qua Hình Ảnh</p>
                    </div>
                    <div className="feature-item">
                        <Icon className="mic-icon" />
                        <p>Luyện Nghe Và Nói Phản Xạ</p>
                    </div>
                </div>
            </Block>

            {/* FAQ Section */}
            <Block className="faq">
                <h3 className="faq-title">Câu Hỏi Thường Gặp</h3>
                <AccordionContent>
                    <div className="accordion-list-item">
                        <div className="accordion-item">
                            <div className="item-title">Tôi đã học tiếng Anh nhiều năm nhưng không nói được, Wordbank có phù hợp không?</div>
                            <p>Hoàn toàn phù hợp! Wordbank giúp bạn biến lý thuyết thành kỹ năng giao tiếp thực tế.</p>
                        </div>
                    </div>
                </AccordionContent>
            </Block>

            {/* Footer Section */}
            <Block className="footer">
                <p>Wordbank 2024</p>
                <Button fill className="upgrade-button">Nâng cấp ngay</Button>
                <p><a href="#">Privacy Policy</a> | <a href="#">Terms of Use</a></p>
            </Block>
        </Page>
    );
};

export default RegisterLandingPage;
