import React from 'react';
import { Navbar, Page, Block } from 'framework7-react';

export default () => (
  <Page hideNavbarOnScroll>
    <Navbar title="Hide Navbar On Scroll" backLink="Back"></Navbar>
    <Block strong>
      <p>Navbar will be hidden if you scroll bottom</p>
    </Block>
    <Block>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos maxime incidunt id ab culpa
        ipsa omnis eos, vel excepturi officiis neque illum perferendis dolorum magnam rerum natus
        dolore nulla ex.
      </p>
      <p>
        Eum dolore, amet enim quaerat omnis. Modi minus voluptatum quam veritatis assumenda,
        eligendi minima dolore in autem delectus sequi accusantium? Cupiditate praesentium autem
        eius, esse ratione consequuntur dolor minus error.
      </p>
      <p>
        Repellendus ipsa sint quisquam delectus dolore quidem odio, praesentium, sequi temporibus
        amet architecto? Commodi molestiae, in repellat fugit! Laudantium, fuga quia officiis error.
        Provident inventore iusto quas iure, expedita optio.
      </p>
      <p>
        Eligendi recusandae eos sed alias delectus reprehenderit quaerat modi dolor commodi beatae
        temporibus nisi ullam ut, quae, animi esse in officia nesciunt sequi amet repellendus?
        Maiores quos provident nisi expedita.
      </p>
      <p>
        Dolorem aspernatur repudiandae aperiam autem excepturi inventore explicabo molestiae atque,
        architecto consequatur ab quia quaerat deleniti quis ipsum alias itaque veritatis maiores
        consectetur minima facilis amet. Maiores impedit ipsum sint.
      </p>
      <p>
        Consequuntur minus fugit vitae magnam illo quibusdam. Minima rerum, magnam nostrum id error
        temporibus odio molestias tempore vero, voluptas quam iusto. In laboriosam blanditiis,
        ratione consequuntur similique, quos repellendus ex!
      </p>
      <p>
        Error suscipit odio modi blanditiis voluptatibus tempore minima ipsam accusantium id! Minus,
        ea totam veniam dolorem aspernatur repudiandae quae similique odio dolor, voluptate quis aut
        tenetur porro culpa odit aliquid.
      </p>
      <p>
        Aperiam velit sed sit quaerat, expedita tempore aspernatur iusto nobis ipsam error ut
        sapiente delectus in minima recusandae dolore alias, cumque labore. Doloribus veritatis
        magni nisi odio voluptatum perferendis placeat!
      </p>
      <p>
        Eaque laboriosam iusto corporis iure nemo ab deleniti ut facere laborum, blanditiis neque
        nihil dignissimos fuga praesentium illo facilis eos beatae accusamus cumque molestiae
        asperiores cupiditate? Provident laborum officiis suscipit!
      </p>
      <p>
        Exercitationem odio nulla rerum soluta aspernatur fugit, illo iusto ullam similique.
        Recusandae consectetur rem, odio autem voluptate similique atque, alias possimus quis vitae
        in, officiis labore deserunt aspernatur rerum sunt?
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos maxime incidunt id ab culpa
        ipsa omnis eos, vel excepturi officiis neque illum perferendis dolorum magnam rerum natus
        dolore nulla ex.
      </p>
      <p>
        Eum dolore, amet enim quaerat omnis. Modi minus voluptatum quam veritatis assumenda,
        eligendi minima dolore in autem delectus sequi accusantium? Cupiditate praesentium autem
        eius, esse ratione consequuntur dolor minus error.
      </p>
      <p>
        Repellendus ipsa sint quisquam delectus dolore quidem odio, praesentium, sequi temporibus
        amet architecto? Commodi molestiae, in repellat fugit! Laudantium, fuga quia officiis error.
        Provident inventore iusto quas iure, expedita optio.
      </p>
      <p>
        Eligendi recusandae eos sed alias delectus reprehenderit quaerat modi dolor commodi beatae
        temporibus nisi ullam ut, quae, animi esse in officia nesciunt sequi amet repellendus?
        Maiores quos provident nisi expedita.
      </p>
      <p>
        Dolorem aspernatur repudiandae aperiam autem excepturi inventore explicabo molestiae atque,
        architecto consequatur ab quia quaerat deleniti quis ipsum alias itaque veritatis maiores
        consectetur minima facilis amet. Maiores impedit ipsum sint.
      </p>
      <p>
        Consequuntur minus fugit vitae magnam illo quibusdam. Minima rerum, magnam nostrum id error
        temporibus odio molestias tempore vero, voluptas quam iusto. In laboriosam blanditiis,
        ratione consequuntur similique, quos repellendus ex!
      </p>
      <p>
        Error suscipit odio modi blanditiis voluptatibus tempore minima ipsam accusantium id! Minus,
        ea totam veniam dolorem aspernatur repudiandae quae similique odio dolor, voluptate quis aut
        tenetur porro culpa odit aliquid.
      </p>
      <p>
        Aperiam velit sed sit quaerat, expedita tempore aspernatur iusto nobis ipsam error ut
        sapiente delectus in minima recusandae dolore alias, cumque labore. Doloribus veritatis
        magni nisi odio voluptatum perferendis placeat!
      </p>
      <p>
        Eaque laboriosam iusto corporis iure nemo ab deleniti ut facere laborum, blanditiis neque
        nihil dignissimos fuga praesentium illo facilis eos beatae accusamus cumque molestiae
        asperiores cupiditate? Provident laborum officiis suscipit!
      </p>
      <p>
        Exercitationem odio nulla rerum soluta aspernatur fugit, illo iusto ullam similique.
        Recusandae consectetur rem, odio autem voluptate similique atque, alias possimus quis vitae
        in, officiis labore deserunt aspernatur rerum sunt?
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos maxime incidunt id ab culpa
        ipsa omnis eos, vel excepturi officiis neque illum perferendis dolorum magnam rerum natus
        dolore nulla ex.
      </p>
      <p>
        Eum dolore, amet enim quaerat omnis. Modi minus voluptatum quam veritatis assumenda,
        eligendi minima dolore in autem delectus sequi accusantium? Cupiditate praesentium autem
        eius, esse ratione consequuntur dolor minus error.
      </p>
      <p>
        Repellendus ipsa sint quisquam delectus dolore quidem odio, praesentium, sequi temporibus
        amet architecto? Commodi molestiae, in repellat fugit! Laudantium, fuga quia officiis error.
        Provident inventore iusto quas iure, expedita optio.
      </p>
      <p>
        Eligendi recusandae eos sed alias delectus reprehenderit quaerat modi dolor commodi beatae
        temporibus nisi ullam ut, quae, animi esse in officia nesciunt sequi amet repellendus?
        Maiores quos provident nisi expedita.
      </p>
      <p>
        Dolorem aspernatur repudiandae aperiam autem excepturi inventore explicabo molestiae atque,
        architecto consequatur ab quia quaerat deleniti quis ipsum alias itaque veritatis maiores
        consectetur minima facilis amet. Maiores impedit ipsum sint.
      </p>
      <p>
        Consequuntur minus fugit vitae magnam illo quibusdam. Minima rerum, magnam nostrum id error
        temporibus odio molestias tempore vero, voluptas quam iusto. In laboriosam blanditiis,
        ratione consequuntur similique, quos repellendus ex!
      </p>
      <p>
        Error suscipit odio modi blanditiis voluptatibus tempore minima ipsam accusantium id! Minus,
        ea totam veniam dolorem aspernatur repudiandae quae similique odio dolor, voluptate quis aut
        tenetur porro culpa odit aliquid.
      </p>
      <p>
        Aperiam velit sed sit quaerat, expedita tempore aspernatur iusto nobis ipsam error ut
        sapiente delectus in minima recusandae dolore alias, cumque labore. Doloribus veritatis
        magni nisi odio voluptatum perferendis placeat!
      </p>
      <p>
        Eaque laboriosam iusto corporis iure nemo ab deleniti ut facere laborum, blanditiis neque
        nihil dignissimos fuga praesentium illo facilis eos beatae accusamus cumque molestiae
        asperiores cupiditate? Provident laborum officiis suscipit!
      </p>
      <p>
        Exercitationem odio nulla rerum soluta aspernatur fugit, illo iusto ullam similique.
        Recusandae consectetur rem, odio autem voluptate similique atque, alias possimus quis vitae
        in, officiis labore deserunt aspernatur rerum sunt?
      </p>
    </Block>
  </Page>
);
