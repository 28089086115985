export const ensurePrefix = (str, prefix) => 
  str.startsWith(prefix) ? str : `${prefix}${str}`;

export const withoutSuffix = (str, suffix) =>
  str.endsWith(suffix) ? str.slice(0, -suffix.length) : str;

export const withoutPrefix = (str, prefix) =>
  str.startsWith(prefix) ? str.slice(prefix.length) : str;

export const convertQuestionToHtml = (str) => {
  if (!str) return '';
  const r = str.split(' ');
  let currentIndex = 0;
  const t = r.map((e, i) => 
    e === "___"
      ? `<input type="text" id='option_${currentIndex++}' style="width: 80px" class='input-group-sm'></input>`
      : e
  );
  return `<div id='questionText'>${t.join(" ")}</div>`;
};

export const convertAnswerToHtml = (str) => {
  if (!str) return '';
  const r = str.split(' ');
  const t = r.map((e, i) => 
    `<span id='txt-${e.toLowerCase()}-${i}'>${e}</span>`
  );
  return `<h6 id='questionText'>${t.join(" ")}</h6>`;
};

export const getImageUrl = (path) => {
  if (!path) return "";
  return 'https://core.wordbank.app' + path;
};

export const getAudioUrl = (path) => {
  if (!path) return "";
  return 'https://core.wordbank.app' + path;
};

export const cleanSentence = (sentence) => {
  // Replace punctuation with space
  return sentence.replace(/[.,!?;:]/g, '').toLowerCase();
};

export const splitSentenceIntoWords = (sentence) => {
  const cleanedSentence = cleanSentence(sentence);
  return cleanedSentence.trim().split(/\s+/).map(m => m.toLowerCase());
};
