import React, { useState } from 'react';
import {
    Page,
    Block,
    List,
    ListInput,
    Link,
} from 'framework7-react';
import myLogo from '../img/Wordbank.svg';
import store from '../store';


const LoginPage = ({ f7router }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');

    const login = () => {
        store.dispatch('loginUser', { user: { email: username, password: password }, f7router, onLoginCompleted:(err)=>{
              setLoginError(err)
          } })
    }
    const register = () => {
        store.dispatch('registerUser', { user: { email: username, password: password }, f7router, onLoginCompleted:(err)=>{
            setLoginError(err)
        } })
    }
    return (
        <Page className="login-page">           
            {/* Header Section */}
            <section className="header-section">
                <Block className="logo">
                    <img src={myLogo} alt="Logo" />
                </Block>

                <Block className="subtitle">
                    <p>We have sent the password to your email.<br></br>
                        If you do not see the email, please check your spam folder.</p>
                </Block>

                {/* Login Form Section */}

                <Block className="login-form-section">
                    <List strongIos insetIos>
                        <ListInput outline type="text" placeholder="Your email" clearButton value={username}
                            onInput={(e) => {
                                setUsername(e.target.value);
                            }}>
                            <i className="ny icon-user-fill" slot="media" />
                        </ListInput>

                        <ListInput outline type="password" placeholder="Your password" clearButton value={password}
                            onInput={(e) => {
                                setPassword(e.target.value);
                            }}>
                            <i className="ny icon-padlock" slot="media" />

                        </ListInput>
                    </List>
                </Block>
                {/* Forgot Password */}


            </section>
            {/* Forgot Password*/}
            <section className='forgot-password'>

                <div className="text-show">Forgot Password</div>
                {loginError && <Block >
                <p style={{color:'red'}}>{loginError}</p>
              </Block>}
                <Link className="module_login" onClick={login}>Login</Link>
                <Link className="createAnAccount" onClick={register}>Create an account</Link>
            </section>

        </Page>
    );
};

export default LoginPage;
