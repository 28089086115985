import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
  useStore, Page, Block, Button, Navbar, NavLeft, NavRight, Link, f7, SkeletonBlock, LoginScreen,
  ListInput,
  List
} from 'framework7-react';
import manHearMusic from '../img/man-hear-music.png';
import store from '../store';
import CustomToolbar from '../components/CustomToolbar';
import CustomPanel from './custome-panel';
import { cleanSentence, getImageUrl } from '../utils/string';
import 'regenerator-runtime/runtime';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { toast } from "react-toastify";
import Scrollbar from 'smooth-scrollbar';
import myLogo from '../img/Wordbank.svg';

const FillTheForm = () => {
  const f7router = f7.views.main.router; // Truy cập f7router từ view chính
  const correctAnswers = [
    'we will clarify',
    'the cancellation policy',
    'our booking',
    'before confirming'
  ];



  const [loginScreenOpened, setLoginScreenOpened] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');

  const openLoginScreen = () => {
    setLoginScreenOpened(true);
  };

  const closeLoginScreen = () => {
    // Use Framework7's modal methods for consistent behavior
    f7.loginScreen.close('.demo-login-screen');
    setLoginScreenOpened(false); // Ensure the state is reset
  };

  const signIn = () => {
   
  store.dispatch('loginUser', { user: { email: username, password: password , isModal: true}, f7router, onLoginCompleted:(err)=>{
    if(err){
      setLoginError(err)
    }else{
      closeLoginScreen()
    }
  } })

    /* f7.dialog.alert(`Username: ${username}<br>Password: ${password}`, () => {
      closeLoginScreen();
    }); */
  };

  const scrollRef = useRef(null);

  useEffect(() => {
    const scrollbar = Scrollbar.init(scrollRef.current, {
      damping: 0.1,
    });

    return () => {
      scrollbar.destroy();
    };
  }, []);

  const [loaded, setLoaded] = useState(false);
  const [inputValues, setInputValues] = useState(Array(correctAnswers.length).fill(''));
  const [currentIndex, setCurrentIndex] = useState(0);
  const [highlightedButtons, setHighlightedButtons] = useState(Array(correctAnswers.length).fill(false));
  const [remainingChoices, setRemainingChoices] = useState(correctAnswers);
  const [phrases, setPhrases] = useState(correctAnswers);
  const quiz = useStore('currentQuiz'); // Thêm kiểm tra an toàn
  const user = useStore('user');
  const score = useStore('score');
  const translateText = useStore('currentTranslate') || "";
  const speaking = useStore('browserSpeaking');
  const [disableAdvance, setDisableAdvance] = useState(0);

  const inputRefs = useRef([]);  // Tham chiếu tới tất cả các input elements
  const hiddenDivRefs = useRef([]); // Tham chiếu tới tất cả các div hidden
  const {
    transcript,
    interimTranscript,
    finalTranscript,
    isMicrophoneAvailable,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();
  let turnOnMic = false;

  const shuffleWords = useCallback((words) => {
    if (words) {
      let shuffled;
      do {
        shuffled = [...words].sort(() => Math.random() - 0.5);
      } while (shuffled.every((word, index) => word === words[index]));
      return shuffled;
    }
    return [];
  }, []);

  useEffect(() => {
    if (quiz?.phrases) {
      try {
        const phrase = JSON.parse(quiz?.phrases);
        setPhrases(phrase);
        setRemainingChoices(shuffleWords(phrase));
        setHighlightedButtons(Array(phrase.length).fill(false));
        setCurrentIndex(0);
        setInputValues(Array(phrase.length).fill(''));
      } catch (error) {
        console.error('Error parsing quiz phrases:', error);
      }
    }
    if (!quiz) {
      store?.dispatch('loadQuiz');
    }
    setDisableAdvance(0);
    resetTranscript();
  }, [quiz]);

  useEffect(() => {
    if (remainingChoices.length === 1) {
      store?.dispatch('loadTranslate');
    }
    if (remainingChoices.length === 0) {
      setDisableAdvance(1);
      const randomNumber = Math.floor(Math.random() * phrases.length || 0);

      setTimeout(() => {
        store.dispatch('handleSpeech', {
          onCompleted: () => {
            setInputValues(prevInputValues => {
              return prevInputValues.map((item, index) =>
                index === randomNumber ? 'write' : item
              );
            });
          }
        });
        setInputValues(prevInputValues => {
          return prevInputValues.map((item, index) =>
            index === randomNumber ? 'shake' : item
          );
        });
      }, 500);
      /* setTimeout(() => {
        setInputValues(prevInputValues => {
          return prevInputValues.map((item, index) =>
            index === randomNumber ? 'write' : item
          );
        });
      }, 5000); */
    }
  }, [remainingChoices, phrases]);

  useEffect(() => {
    // Cập nhật chiều rộng của tất cả các input khi component render hoặc khi inputValues thay đổi
    inputRefs.current.forEach((input, index) => {
      const hiddenDiv = hiddenDivRefs.current[index];
      if (hiddenDiv && input) {
        input.style.width = `${hiddenDiv.offsetWidth}px`;
      }
    });
  }, [inputValues, remainingChoices]);

  const handleButtonClick = (e, value, buttonIndex) => {
    // Thực hiện đọc chữ đã chọn
    store.dispatch('handleSpeech', { text: value });
    store.dispatch('handleSubmitOption', { optionIndex: currentIndex, answerText: value });

    if (value === phrases[currentIndex]) {
      // Chọn phần tử button đã được chọn đúng
      const buttonElement = e.target
      // Thêm hiệu ứng rung
      buttonElement.classList.add('shake');
      // Xóa lớp 'shake' sau khi hiệu ứng kết thúc
      buttonElement.addEventListener('animationend', () => {
        buttonElement.classList.remove('shake');
      });
      // Cập nhật giá trị đúng vào ô trống
      const newInputValues = [...inputValues];
      newInputValues[currentIndex] = value;
      setInputValues(newInputValues);
      setCurrentIndex(currentIndex + 1);




      // Sau khi biến mất xong thì xóa nút khỏi màn hình
      setTimeout(() => {
        setRemainingChoices((prevChoices) => {
          console.log("🚀 ~ setRemainingChoices ~ prevChoices:", prevChoices)
          const t = prevChoices.filter((item) => {
            return item !== value
          })
          console.log("🚀 ~ setRemainingChoices ~ t:", t)

          return t

        })
      }, 1000); // 1400ms: thời gian cho cả shake và fade-out
    } else {
      // Hiển thị hiệu ứng màu đỏ trên nút button khi chọn sai
      const newHighlightedButtons = [...highlightedButtons];
      newHighlightedButtons[buttonIndex] = true;
      setHighlightedButtons(newHighlightedButtons);

      // Xóa highlight sau một thời gian ngắn
      setTimeout(() => {
        const resetHighlightedButtons = [...highlightedButtons];
        resetHighlightedButtons[buttonIndex] = false;
        setHighlightedButtons(resetHighlightedButtons);
      }, 400);
    }
  };

  const handleBlur = (e, originText) => {
    if (e.target.value) {
      e.target.disabled = true;
      if (cleanSentence(e.target.value) === cleanSentence(originText)) {
        e.target.closest('div').classList.add('correct');
        setDisableAdvance(2.5);
      } else {
        e.target.closest('div').classList.add('wrong');
        setDisableAdvance(2);
      }
    }
  };

  const handleKeyDown = (e, originText) => {
    if (e.key === 'Enter' || (e.key === ' ' && cleanSentence(e.currentTarget.value) === cleanSentence(originText))) {
      e.preventDefault();
      e.currentTarget.blur();
    }
  };

  const startListening = () => {
    if (!isMicrophoneAvailable) {
      toast("Microphone not available!", { type: 'error' });
      return;
    }
    if (!browserSupportsSpeechRecognition) {
      toast('Browser does not support speech recognition', { type: 'error' });
      return;
    }
    if (speaking) return;

    if (!listening) {
      resetTranscript();
      SpeechRecognition.startListening({ continuous: false, interimResults: true, language: 'en' }).then(() => {
        turnOnMic = true;
      }).catch((e) => {
        console.log(e);
      });
    } else {
      SpeechRecognition.stopListening();
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
          stream.getTracks().forEach(track => track.stop());
        }).catch(error => {
          console.error('stop micro:', error);
        });
      }
    }
    setDisableAdvance(3);
  };

  useEffect(() => {
    if (!listening && turnOnMic) {
      SpeechRecognition.stopListening();
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
          stream.getTracks().forEach(track => track.stop());
        }).catch(error => {
          console.error('stop micro:', error);
        });
      }
    }
  }, [listening]);

  return (
    <Page className="safe-areas white-bg fill-form-page">
      <Navbar className='white-head'>
        <NavLeft>
          <Link panelOpen="#panel-nested" style={{ marginRight: '0px' }} ><i className="ny icon-dot" />  </Link>
          <Link>
            <div className="heart">
              <i className="ny icon-heart-icon heart-icon" />
              <span className="heart-count">{score}</span>
            </div>
          </Link>
        </NavLeft>
        <NavRight>
          <Link className="get-likes-button">
            <i className="ny icon-share-icon share-icon" />
            <span className="get-likes-text">Get 21</span>
            <i className="ny icon-heart-icon get-likes-heart-icon" />
          </Link>
        </NavRight>
      </Navbar>
      <CustomPanel containerEl="#panel-page" />
      {/* Hình ảnh chính */}
      <Block className="image-block">
        {!loaded && (
          <SkeletonBlock
            className="skeleton-image"
            width="100%"
            height="400px"
            effect="wave"
            style={{ marginBottom: '10px' }}
          />
        )}

        <img
          src={getImageUrl(quiz?.image_url) || manHearMusic}
          alt="Listening to music"
          className={`main-image ${loaded ? 'loaded' : 'loading'}`}
          onLoad={() => setLoaded(true)}
          style={{ width: '100%', display: loaded ? 'block' : 'none' }}
        />
      </Block>

      {/* Phần bài tập */}
      <Block strong className="bg-block">
        <div className="exercise-title">{quiz?.topic || "Default Topic"}</div>

        <div className="fill-in-the-blank">
          {phrases.map((item, index) => {
            if (inputValues[index] === "write") {
              return (<div className="item-inner" key={index}>
                <div className="item-input-wrap fill-blank-container">
                  <span
                    style={{ position: 'absolute', visibility: 'hidden', whiteSpace: 'pre' }}
                    className='fill-blank-container'
                    ref={(el) => hiddenDivRefs.current[index] = el}
                  >
                    {item || '\u00A0'}
                  </span>
                  <input
                    className='fill-blank-container'
                    ref={(el) => inputRefs.current[index] = el}
                    type="text"
                    name={index}
                    autoFocus={true}
                    style={{ width: '3.563rem' }}
                    onBlur={(e) => { handleBlur(e, item) }}
                    onKeyDown={(e) => handleKeyDown(e, item)}
                  />
                </div>
              </div>)
            }
            else if (inputValues[index] === "shake") {
              return (<div
                key={index}
                className={`fill-blank-container shake ${inputValues[index] ? 'filled' : ''}`}
                style={{ width: inputValues[index] ? 'auto' : '3.563rem' }}
              >
                {phrases[index] || '\u00A0'}
              </div>)
            }
            else
              return (
                <div
                  key={index}
                  className={`fill-blank-container ${inputValues[index] ? 'filled' : ''}`}
                  style={{ width: inputValues[index] ? 'auto' : '3.563rem' }}
                >
                  {inputValues[index] || '\u00A0'}
                </div>
              )

          })}
        </div>
        <div ref={scrollRef} style={{ height: '30vh', overflow: 'hidden' }}>
          <div className='content-inner'>

            <div className="choices">
              {remainingChoices.map((answer, index) => (
                <Button
                  key={index}
                  fill
                  className={`choice-button ${highlightedButtons[index] ? 'highlighted' : ''}`}
                  onClick={(e) => handleButtonClick(e, answer, index)}
                >
                  {answer}
                </Button>
              ))}
            </div>

            {remainingChoices.length === 0 && (
              <div className="choices">
                {transcript ? transcript : disableAdvance === 2 ? quiz.content : translateText}
              </div>
            )}
           
            <p style={{ paddingTop: '.3rem' }}>
              Câu “How are you today?” là một câu hỏi đơn giản và thường được sử dụng trong giao tiếp hàng ngày để hỏi thăm sức khỏe hoặc tình trạng của ai đó. Dưới đây là phân tích chi tiết:

              1. Loại câu:

              •	Đây là một câu hỏi (Interrogative sentence) bắt đầu bằng từ để hỏi (How), dùng để hỏi về trạng thái hoặc tình hình.

              2. Thành phần câu:

              •	How:
              •	Là từ để hỏi, nghĩa là “như thế nào” hoặc “ra sao”.
              •	Trong câu này, nó hỏi về trạng thái của người nghe.
              •	are:
              •	Là động từ “to be” ở dạng hiện tại (present tense) dành cho ngôi thứ hai số ít và số nhiều (you).
              •	Có chức năng liên kết chủ ngữ (you) với thông tin về trạng thái.
              you:
              •	Là đại từ nhân xưng, nghĩa là “bạn” (hoặc “các bạn” trong trường hợp số nhiều).
              •	Đây là chủ ngữ của câu.
              •	today:
              •	Là trạng từ chỉ thời gian, nghĩa là “hôm nay”.
              •	Được sử dụng để giới hạn ngữ cảnh câu hỏi, cụ thể là trạng thái của người nghe vào hôm nay.

              3. Cấu trúc ngữ pháp:

              Câu này tuân theo cấu trúc cơ bản của một câu hỏi dùng động từ “to be”:

              How + to be (are) + subject (you) + (optional time expression)?
            </p>
          </div>
        </div>




        {/* Bottom Toolbar Component */}
        <CustomToolbar
          disableAdvance={disableAdvance}
          startListening={startListening}
          handleSpeech={() => store.dispatch('handleSpeech', {})}
          loadQuiz={() => { if(!user){
            setLoginScreenOpened(true);
            return;
          }
          else store.dispatch('loadNextQuiz', { id: null, f7router })}}
          preQuiz={() => { store.dispatch('loadPreQuiz') }}
          f7router={f7router}
          isListening={listening} // Truyền trạng thái 'listening' vào
          interRim={interimTranscript}
        />
      </Block>
      {/* Login Screen Modal */}
      {/* Login Screen Modal */}
      <LoginScreen
        className="demo-login-screen"
        opened={loginScreenOpened}
        onLoginScreenClosed={() => setLoginScreenOpened(false)}
      >
        <Page loginScreen className="login-page">

          {/* Header Section */}

          <section className="header-section">
            <div className='swpap'>
              <Block className="logo">
                <img src={myLogo} alt="Logo" />
              </Block>
              <Block className="subtitle">
                <p>We have sent the password to your email. Check your spam folder if needed.</p>
              </Block>

              {/* Login Form Section */}
              <Block className="login-form-section">
                <List strongIos insetIos>
                  <ListInput
                    outline
                    type="text"
                    placeholder="Your email"
                    clearButton
                    value={username}
                    onInput={(e) => setUsername(e.target.value)}
                  >
                    <i className="ny icon-user-fill" slot="media" />
                  </ListInput>
                  <ListInput
                    outline
                    type="password"
                    placeholder="Your password"
                    clearButton
                    value={password}
                    onInput={(e) => setPassword(e.target.value)}
                  >
                    <i className="ny icon-padlock" slot="media" />
                  </ListInput>
                </List>
              </Block>
            </div>
           
            <section className='forgot-password'>
              <div className="text-show">Forgot Password</div>
              {loginError && <Block >
                <p style={{color:'red'}}>{loginError}</p>
              </Block>}
            {/* Forgot Password */}
              <Link className="module_login" onClick={signIn}>Login</Link>
              <Link className="createAnAccount" onClick={closeLoginScreen}>Cancel</Link>
            </section>
          </section>
        </Page>
      </LoginScreen>
    </Page>
  );
};

export default FillTheForm;
