import React, { useEffect } from 'react';
import { App, Panel, View } from 'framework7-react';
import routes from './routes.js'; // Định nghĩa các routes
import store from './store.js'; // State management nếu cần

export default () => {
  // Xác định theme dựa trên query parameters
  let theme = 'ios';
  if (document.location.search.indexOf('theme=') >= 0) {
    theme = document.location.search.split('theme=')[1].split('&')[0];
  }

  // Cấu hình lịch sử trình duyệt
  const needsBrowserHistory = document.location.href.includes('example-preview');

  // Sử dụng useEffect để thiết lập màu theme khi ứng dụng khởi động
  useEffect(() => {
    // Cập nhật màu theme thông qua biến CSS toàn cục
    document.documentElement.style.setProperty('--f7-theme-color', '#FFA64A');
    document.documentElement.style.setProperty('--f7-color-primary', '#FFA64A');
  }, []);

  return (
    <App
      theme={theme}
      routes={routes}
      store={store}
      popup={{ closeOnEscape: true }}
      sheet={{ closeOnEscape: true }}
      popover={{ closeOnEscape: true }}
      actions={{ closeOnEscape: true }}
      style={{maxWidth:600, justifyContent:'center', justifySelf:'center'}}
    >
      {/* Panel bên trái */}
      <Panel left floating resizable>
        <View url="/panel-left/" linksView=".view-main" />
      </Panel>

      {/* Panel bên phải */}
      <Panel right floating resizable>
        <View url="/panel-right/" />
      </Panel>

      {/* View chính */}
      <View
        url="/"
        main
        className="safe-areas"
        masterDetailBreakpoint={768}
        browserHistory={needsBrowserHistory}
        browserHistoryRoot={needsBrowserHistory ? '/kitchen-sink/react/dist/' : ''}
        preloadPreviousPage={!needsBrowserHistory}
        iosSwipeBack={!needsBrowserHistory}
      />
    </App>
  );
};
