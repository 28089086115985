import React from 'react';
import { Panel, Block, Link, List, ListItem ,useStore} from 'framework7-react';

const CustomPanel = ({ containerEl }) => {
  const user = useStore('user');

  return (
    <Panel left cover containerEl={containerEl} id="panel-nested">
      <Block>
        <Link panelClose>
          <i className="ny icon-back-icon" style={{ marginRight: '5px' }} />
          Back
        </Link>
      </Block>
      {user &&  <List strongIos dividersIos className='panel-text'>
      <ListItem link="/action-sheet/" title="Wallet" panelClose />
      <ListItem link="/user-profile/" title="Profile" panelClose />
    </List>}
    {!user &&  <List strongIos dividersIos className='panel-text'>
      <ListItem link="/login-page/" title="Login" panelClose />
     
    </List>}
     
    </Panel>
  );
};

export default CustomPanel;
