import React from 'react';
import {
  Page,
  Navbar,
  Block,
  Button,
  List,
  Card,
  CardContent,
  CardHeader,
  NavLeft,
  BlockTitle,
  ListInput,
  Link,
  NavRight,
} from 'framework7-react';

import myAvatar from '../img/avatar.svg';

const ProfilePage = (props) => {
  const { f7router } = props; // Truy cập f7router từ props

  return (
    <Page className="profile-page">
      {/* Navbar */}
      <Navbar
        className='header-orange'>
        <NavLeft>
          <Link onClick={() => f7router.back()}><i className="ny icon-back-icon" />Profile</Link>
        </NavLeft>
        <NavRight><a className='icon-heada' href='#'><i className="ny icon-gift-icon" /></a> <a className='icon-headb' href='#'><i className="ny icon-logoff-icon" /></a></NavRight>
      </Navbar>

      {/* Profile Header Section */}
      <div className='orange-swap'>
        <div className='part-top'>
          <Block className="profile-header">
            <img
              src={myAvatar}
              alt="Profile Avatar"
              className="profile-avatar"
            />
            <div className="profile-info">
              <div className="profile-heart">
                <i className='ny icon-heart-icon' />
                <span>1000</span>
              </div>
              <div className="profile-email">figma@gmail.com</div>
            </div>
          </Block>

          {/* Gift Message Section */}
          <Block className="profile-gift-message">
            <div className="gift-title">Vẫn còn nhiều <br></br>phần quà đang chờ bạn nhận.</div>
            <div className="gift-subtitle">
              Chia sẻ bạn bè để nhận thêm hộp quà may mắn.
            </div>
            <Button fill className="gift-share-button">Copy & share</Button>
          </Block>
        </div>
        <div className='white-part'>
          {/* Change Password Section */}
          <Block className='chang-pass-form'>
            <BlockTitle>Change Pasword</BlockTitle>
            <List strongIos insetIos>
              <ListInput
                outline
                label="New password"
                floatingLabel
                type="password"
                placeholder="Your new password"
                clearButton
              >
              </ListInput>
            </List>
          </Block>
          <Block className='chang-pass-form select'>
            <BlockTitle>Setting</BlockTitle>
            <List strongIos insetIos>
              <ListInput outline label="Language" type="select" defaultValue="Vietnamese" placeholder="Please choose...">
                <option value="Vietnamese">Vietnamese</option>
                <option value="English">English</option>
              </ListInput>
              <ListInput outline label="Your level is" type="select" defaultValue="Basic" placeholder="Please choose...">
                <option value="English">Basic</option>
                <option value="Vietnamese">Expert</option>

              </ListInput>
            </List>
          </Block>
          {/* History Payment Section */}
          <Block className='payment-history'>
            <BlockTitle>History Payment</BlockTitle>
            <Card className="data-table">
              <table>
                <thead>
                  <tr>
                    <th className="label-cell">Code</th>
                    <th className="numeric-cell">Amount</th>
                    <th className="numeric-cell">Type</th>
                    <th className="numeric-cell">Created</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="label-cell">DW123-3455-565</td>
                    <td className="numeric-cell" style={{ textAlign: 'right' }}>1.200.000</td>
                    <td className="numeric-cell">Visa</td>
                    <td className="numeric-cell">24/12/2024</td>
                  </tr>
                  <tr>
                    <td className="label-cell">DW223-6547-782</td>
                    <td className="numeric-cell" style={{ textAlign: 'right' }}>2.450.000</td>
                    <td className="numeric-cell">Mastercard</td>
                    <td className="numeric-cell">15/01/2025</td>
                  </tr>
                  <tr>
                    <td className="label-cell">DW873-9812-332</td>
                    <td className="numeric-cell" style={{ textAlign: 'right' }}>980.000</td>
                    <td className="numeric-cell">PayPal</td>
                    <td className="numeric-cell">10/02/2025</td>
                  </tr>
                  <tr>
                    <td className="label-cell">DW453-6578-129</td>
                    <td className="numeric-cell" style={{ textAlign: 'right' }}>3.100.000</td>
                    <td className="numeric-cell">Visa</td>
                    <td className="numeric-cell">05/03/2025</td>
                  </tr>
                  <tr>
                    <td className="label-cell">DW559-7823-441</td>
                    <td className="numeric-cell" style={{ textAlign: 'right' }}>750.000</td>
                    <td className="numeric-cell">Bank Transfer</td>
                    <td className="numeric-cell">18/04/2025</td>
                  </tr>
                </tbody>

              </table>
            </Card>
          </Block>

        </div>

      </div>




    </Page>

  );
};

export default ProfilePage;
