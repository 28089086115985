import React from 'react';
import { Page, BlockTitle, Block, List, ListItem, Link, useStore } from 'framework7-react';
import store from '../store';

export default () => {
  const user = useStore('user');
 return <Page>

    <Block>
      <Link panelClose><i className="ny icon-back-icon" />Back
      </Link>
    </Block>   
    {user? <List strongIos dividersIos className='panel-text'>
      <ListItem link="/top-up/" title="Wallet" panelClose />
      <ListItem link="/user-profile/" title="Profile" panelClose />
      <ListItem  onClick={store?.dispatch('logout')} title="Logout" panelClose />
    </List>: <List strongIos dividersIos className='panel-text'>
      <ListItem link="/login-page/" title="Login" panelClose />
     
    </List>}
     
 
  </Page>
};
