import React, { useState } from 'react';
import {
  Page,
  Navbar,
  Block,
  Button,
  Link,
  List,
  ListInput,
  NavLeft,
  NavRight
} from 'framework7-react';

import myBarcode from '../img/barcode-dm.png';

const TopUpPage = ({ f7router }) => {

  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    'Scan or Copy address',
    'Send 50 USDT to address',
    'Submit your TXID'
  ];

  const contents = [
    (
      <div className="qr-code-block">
        <img src={myBarcode} alt='img' className='img' />
        <div className="wallet-address">
          <span className='nor-text'>0xEsasdfjkashdkjas423askjdxxxhdajkshdk</span>
          <span className='ka-text'>Scan QR code or Copy address<Link><i className="ny icon-copy-icon" /></Link></span>
        </div>
        <div className="network-info">
          <span className="network">Network </span>
          <span className="binanceSmartChainBSC">Binance Smart chain (BSC)</span>
        </div>
        <div className="network-info">
          <span className="network">Memo</span>
          <span className="binanceSmartChainBSC">1548233124</span>
        </div>
        <div className='complete'>
          <span className='textt'>After the transfer is complete. Copy and paste the transaction's TXID below.</span>
        </div>
        <Block className="register-form-section">
          <div className="form-container">
            <input
              type="email"
              className="email-input"
              placeholder="Your Txld"
            />
            <Button fill className="submit-button">
              Submit
            </Button>
          </div>
        </Block>
      </div>
    ),
    (
      <div className="qr-code-block">

        <img src={myBarcode} alt='img' className='img' />
        <div className="network-info">
          <span className="network">Scan QR code </span>
        </div>

        <div className='box-swap'>
          <div className="wallet-address ">
            <span className='nor-text text-left'>Thông Tin Chuyển Khoản:</span>
            <ul className="bank-account-info">
              <li><div className="info-item"><span class="label">Tên tài khoản ngân hàng: Nguyễn Văn A</span></div></li>
              <li><div className="info-item">Số tài khoản: 0123456789<Link style={{ marginLeft: '0.5rem' }}><i className="ny icon-copy-icon" /></Link></div></li>
              <li><div className="info-item">Ngân hàng: Ngân hàng ngoại thương</div></li>
              <li><div className="info-item">Số tiền: 500.000đ</div></li>
              <li><div className="info-item">Nội dung chuyển khoản: X10fhkasjdhf<Link style={{ marginLeft: '0.5rem' }}><i className="ny icon-copy-icon" /></Link></div></li>
            </ul>

          </div>
          <div className="wallet-address ">
            <span className='nor-text text-left'>Thời Gian Xác Nhận</span>
            <ul className="bank-account-info">
              <li><div className="info-item"><span class="label">Thông báo: " Đối tác chúng tôi sẽ xác nhận giao dịch ngay sau khi nhận được thanh toán."</span></div></li>
            </ul>
          </div>
        </div>

        {/* Go back to home button */}
        <Block className="btn-receipt">
          <Link className="receipt-button">Tải biên lai</Link>
        </Block>
      </div>
    ),
    (
      <div>
        <h3>Step 3</h3>
        <p>Submit your TXID to complete verification.</p>
      </div>
    )
  ];

  const handleStepClick = (index) => {
    setActiveStep(index);
  };

  return (
    <Page className="top-up-page">
      {/* Navbar */}
      <Navbar
        className='header-orange'>
        <NavLeft>
          <Link onClick={() => f7router.back()}><i className="ny icon-back-icon" />Nạp tiền</Link>
        </NavLeft>
        <NavRight>
          <Link className="get-info-btn">
            <span className="get-money">100$</span>
            <i className="ny icon-hand-get-icon" style={{ fontSize: '1.4rem' }} />

          </Link>
        </NavRight>
      </Navbar>

      {/* Order and Promo Code Section */}
  
      <Block className="order-block">
        <div className="order-info">    
          <div className="header-topup">Nạp tiền</div>   
        </div>
        <List strongIos insetIos className='cs-list'> 
          <label>Phương thức thanh toán</label>             
              <ListInput  outline  type="select" defaultValue="Tether (USDT BSC)" placeholder="Please choose...">
                <option value="Tether (USDT BSC)">Tether (USDT BSC)</option>
                <option value="AKE (UKSA SVS)">AKE (UKSA SVS)</option>
              </ListInput>
              </List>
  
        {/* Payment Instructions Section */}
        <Block className="payment-instructions">
          {/* Step Headers */}
          <div className="steps">
            {steps.map((text, index) => (
              <div
                className={`step ${activeStep === index ? 'active' : ''}`}
                key={index}
                onClick={() => handleStepClick(index)}
              >
                <div className="step-number">{index + 1}</div>
                <div className="step-text">{text}</div>
              </div>
            ))}
          </div>

          {/* Step Contents */}
          <div className="step-content">
            {contents.map((content, index) => (
              <div
                key={index}
                className={`content-item ${activeStep === index ? 'active' : ''} ${activeStep > index ? 'left' : 'right'
                  }`}
              >
                <div>{content}</div>
              </div>
            ))}
          </div>
        </Block>
      </Block>

      {/* Go back to home button */}
      <Block className="back-home-button-block">
        <Link className="back-home-button">Tiếp tục</Link>
      </Block>
    </Page>
  );
};

export default TopUpPage;

