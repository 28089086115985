import React from 'react';
import { Navbar, Page, Block, Subnavbar } from 'framework7-react';

export default () => (
  <Page>
    <Navbar backLink="Back">
      <Subnavbar title="Page Title"></Subnavbar>
    </Navbar>
    <Block>
      <p>
        It also possible to use Subnavbar to display page title and keep navbar only for actions.
      </p>
    </Block>
    <Block>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde, consequatur quia amet
        voluptate vero quasi, veniam, quisquam dolorum magni sint enim, harum expedita excepturi
        quas iure magnam minus voluptatem quaerat!
      </p>
      <p>
        Dolore laboriosam error magnam velit expedita recusandae, dolor asperiores unde, sint,
        veritatis illum ipsum? Nulla ratione nobis, ullam debitis. Inventore sapiente rem dolore eum
        ipsa totam perspiciatis cupiditate, amet maiores!
      </p>
      <p>
        Ratione quod minus ipsum maxime cum voluptate molestiae adipisci placeat ut illo, alias
        nobis perferendis magni odio sunt, porro, totam praesentium possimus! Autem inventore ut
        provident animi quae, impedit id!
      </p>
      <p>
        Aperiam ea ab harum. Quis dolorem cupiditate, incidunt mollitia ducimus voluptatem commodi!
        Odio quasi amet hic nesciunt, quibusdam, est vero repellat sapiente perferendis, optio
        laboriosam in culpa veniam alias ad.
      </p>
      <p>
        A fuga corporis harum velit maiores, quaerat accusantium cum aspernatur consequuntur dolor
        vel fugit omnis est dolorum delectus debitis aperiam distinctio eveniet vero nihil
        voluptatum culpa. Accusamus aliquid facere tenetur?
      </p>
    </Block>
  </Page>
);
