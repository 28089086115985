import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app'; // Import thành phần chính (App)

// Tạo container DOM
const container = document.createElement('div'); // Tạo <div> động
container.id = 'app'; // Gán id="app"
document.body.appendChild(container); // Thêm <div> vào <body>

// Tạo root React và render ứng dụng
const root = createRoot(container);
root.render(<App />);

