// lib/apiHelper.js
import axios from 'axios';
import { f7 } from 'framework7-react';

const BASE_URL = 'https://core.lovestudy.app/api';

const apiClient = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error('Request Error:', error);
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      console.error('Response Error Data:', error.response.data);
      console.error('Response Error Status:', error.response.status);
      if (error.response.status === 401) {
        localStorage.removeItem('custom-auth-token');
        localStorage.removeItem('custom-user-info');
        if (f7 && f7.views && f7.views.main) {
          f7.views.main.router.navigate('/login-page/');
        }
       // window.location.href = '/auth/sign-in';
      }
      let data = error.response.data;
      return Promise.reject(data['message'] || error.response.data);
    } else if (error.request) {
      console.error('Response Error Request:', error.request);
    } else {
      console.error('Response Error Message:', error.message);
    }

    return Promise.reject(error);
  }
);

export const get = async (endpoint, params) => {
  try {
    const response = await apiClient.get(endpoint, { params });
    return response.data;
  } catch (error) {
    console.error('API GET error:', error);
    throw error;
  }
};

export const post = async (endpoint, data) => {
  try {
    const response = await apiClient.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error('API POST error:', error);
    throw error;
  }
};

export const put = async (endpoint, data) => {
  try {
    const response = await apiClient.put(endpoint, data);
    return response.data;
  } catch (error) {
    console.error('API PUT error:', error);
    throw error;
  }
};

export const patch = async (endpoint, data) => {
  try {
    const response = await apiClient.patch(endpoint, data);
    return response.data;
  } catch (error) {
    console.error('API PATCH error:', error);
    throw error;
  }
};

export const del = async (endpoint) => {
  try {
    const response = await apiClient.delete(endpoint);
    return response.data;
  } catch (error) {
    console.error('API DELETE error:', error);
    throw error;
  }
};

export const setAuthToken = (token) => {
  if (token) {
    localStorage.setItem('authToken', token);
    apiClient.defaults.headers['Authorization'] = `Bearer ${token}`;
  } else {
    localStorage.removeItem('authToken');
    delete apiClient.defaults.headers['Authorization'];
  }
};
